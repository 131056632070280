<template>
  <section class="section is-flex is-flex-direction-column h-100">
    <!-- <h2 class="title">
      {{ $t('route.threats-all') }}
    </h2> -->

    <div class="columns h-100">
      <div class="column h-100" style="flex: 0 0 20rem;">
        <box style="margin-bottom: 0;" class="filters is-flex is-flex-direction-column h-100">
          <div slot="header">
            {{ $t('filters') }}
          </div>
          <overlay-scrollbars :options="{ className: isDark ? 'os-theme-light' : 'os-theme-dark' }" class="pr-3 h-100">
            <ul class="is-flex is-flex-direction-column h-100">
              <li class="pb-2 filter">
                <strong class="is-uppercase">{{ $t('threats.search') }}</strong>
                <ul>
                  <li class="py-1">
                    <input
                      v-model="filterSearch" class="input is-small" type="text"
                      placeholder="Search"
                    >
                  </li>
                </ul>
              </li>
              <li class="mt-2 pb-2 filter">
                <strong class="is-uppercase">{{ $t('threats.resource') }}</strong>
                <ul>
                  <li class="py-1">
                    <input
                      v-model="filterResource" class="input is-small" type="text"
                      placeholder="malicious.com"
                    >
                  </li>
                </ul>
              </li>
              <li class="mt-2 pb-2 filter">
                <strong class="is-uppercase">{{ $t('client') }}</strong>
                <ul>
                  <li class="py-1">
                    <input
                      v-model="filterClient" class="input is-small" type="text"
                      placeholder="192.168.1.146"
                    >
                  </li>
                </ul>
              </li>
              <li class="mt-2 pb-2 filter">
                <strong class="is-uppercase">{{ $t('severity') }}</strong>
                <ul class="is-size-65">
                  <li
                    v-for="(severity, id) in severities" :key="id"
                    class="py-1"
                  >
                    <label class="is-flex is-align-items-center checkbox">
                      <input v-model="filterSeverity" type="checkbox" :value="id">
                      <span class="is-capitalized" style="width: 100%">{{ $t(id) }}</span>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="mt-2 pb-2 filter">
                <strong class="is-uppercase">MITRE ATT&CK</strong>
                <ul class="is-size-65">
                  <li
                    v-for="tactic in attacksArr" :key="tactic.id"
                    class="pr-3"
                  >
                    <label class="is-flex is-align-items-center checkbox">
                      <input
                        type="checkbox"
                        :value="tactic.id" :checked="tactic.checked" @change="checkAttack($event, tactic)"
                      >
                      <span class="is-capitalized" style="width: 100%">{{ attackName(tactic.name) }}</span>
                      <span class="p-1" @click.prevent="toggleOpen(tactic.id)"><octicon :icon="open[tactic.id] ? chevronUp : chevronDown" /></span>
                    </label>
                    <ul v-show="open[tactic.id]" class="pl-3">
                      <li
                        v-for="(technique, tid) in tactic.children" :key="tid"
                      >
                        <label class="is-flex is-align-items-center checkbox">
                          <input
                            type="checkbox"
                            :value="tid" :checked="technique.checked" @change="checkAttack($event, technique, tactic)"
                          >
                          <span class="is-capitalized truncate" style="width: 100%"> {{ attackName(technique.name) }}</span>
                          <span v-if="attackHasChildren(technique)" class="p-1" @click.prevent="toggleOpen(tid)"><octicon :icon="open[tid] ? chevronUp : chevronDown" /></span>
                          <span v-else class="p-1">&nbsp;</span>
                        </label>
                        <ul v-show="open[tid]" class="pl-3 is-size-65">
                          <li v-for="(subTechnique, stid) in technique.children" :key="stid">
                            <label class="is-flex is-align-items-center checkbox">
                              <input
                                type="checkbox"
                                :value="stid" :checked="subTechnique.checked" @change="checkAttack($event, subTechnique, technique, tactic)"
                              >
                              <span class="is-capitalized truncate" style="width: 100%"> {{ attackName(subTechnique.name) }}</span>
                              <span class="p-1">&nbsp;</span>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="mt-2 filter">
                <strong class="is-uppercase">{{ $t('threats.engine') }}</strong>
                <ul class="is-size-65">
                  <li
                    v-for="(engine, id) in engines" :key="id"
                    class="py-1"
                  >
                    <label class="is-flex is-align-items-center checkbox">
                      <input v-model="filterEngine" type="checkbox" :value="id">
                      <span class="is-capitalized" style="width: 100%">{{ id }}</span>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </overlay-scrollbars>
        </box>
      </div>
      <div class="column">
        <box style="margin-bottom: 0" class="is-size-65">
          <data-table
            ref="table"
            v-resize="onResize"
            :items="itemsDebounced"
            :theme="$_ui_theme_tables"
            :per-page="15"
            :pagination="['top', 'bottom']" disable-filtering sort-desc
            sort-by="seen_at"
          >
            <data-column
              field="seen_at"
              :label="$t('time')"
              width="12%"
            >
              <template slot-scope="{ value }">
                <span>{{ value | dmyhs }}</span>
              </template>
            </data-column>
            <data-column
              field="src_host"
              :label="$t('client')"
              width="10%"
            />
            <data-column
              field="resource"
              :label="$t('threats.resource')"
              width="10%"
            />
            <data-column
              field="sensor_name"
              :label="$t('threats.sensor')"
              :sortable="false"
              width="9%"
            />
            <data-column
              :label="$t('type')"
              :sortable="false"
              width="15%"
            >
              <template slot-scope="prop">
                <span class="is-capitalized">{{ prop.item.type }}</span>
              </template>
            </data-column>
            <data-column
              field="severity"
              :label="$t('severity')"
              width="95"
            >
              <template slot-scope="{ value }">
                <span :class="['px-2 py-1 tag is-light is-uppercase mr-1', severities[value]]">{{ value }}</span>
              </template>
            </data-column>
            <data-column
              field="description"
              :label="$t('description')"
              :sortable="false"
            >
          <template #default="{item}">
            <div
              style="white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;"
              v-tooltip.auto="{ content: item.description, classes: ['tooltip']  }"
              >
              {{item.description }}
            </div>

            </template>
            </data-column>
            <data-column
              :label="$t('actions')"
              :sortable="false"
              width="90"
            >
              <template slot-scope="props">
                <button
                  class="button is-small is-primary is-light mr-2"
                  @click.prevent="details(props.item)"
                >
                  <octicon :icon="repo" /> <span>{{ $t('details') }}</span>
                </button>
              </template>
            </data-column>
          </data-table>
        </box>
      </div>
    </div>

    <modal v-if="item" :show.sync="modal" content-width="800px">
      <div class="boxy h-100">
        <tabs
          :theme="$_ui_theme_tabs" :show.sync="tab"
          class="is-flex is-flex-direction-column h-100"
        >
          <tab :title="$t('details')">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">ID</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.id"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('threats.resource') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.resource"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('severity') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.severity"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('confidence') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.confidence"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('client') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.src_host"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">MAC</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.src_mac"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('hostname') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.host_name"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('destination') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.dst_host"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('techniques') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      v-if="item.techniques !== 'null'"
                      class="input" type="text" :value="item.techniques"
                      readonly
                    >
                    <input
                      v-else
                      class="input" type="text" value="cant determine"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('tactics') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.tactics"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ $t('time') }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input" type="text" :value="item.seen_at"
                      readonly
                    >
                  </p>
                </div>
              </div>
            </div>
          </tab>

          <tab title="JSON" style="overflow-y: auto">
            <snippet language="json" :code="itemJSON" />
          </tab>

          <tab title="CSV">
            <button class="button is-primary is-small" @click.prevent="itemCSVExport">
              {{ $t('export') }}
            </button>
          </tab>
        </tabs>
      </div>
    </modal>
    <a
      v-if="item" ref="downloader"
      :href="itemCSVURL" :download="`${item.id}.csv`" style="display: none"
    />
  </section>
</template>
<script>
import { DataTable, DataColumn } from 'vue-teible'
import { chevronDown, chevronUp, repo } from 'octicons-vue'
import { Modal, Snippet } from '@cyradar/ui'
import { Tabs, Tab } from '@hiendv/vue-tabs'
import { ThreatSeverities } from '@/store'
import debounce from 'lodash.debounce'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import 'overlayscrollbars/css/OverlayScrollbars.min.css'
import Vue from 'vue'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

export default {
  components: { DataTable, DataColumn, Modal, Snippet, Tabs, Tab, 'overlay-scrollbars': OverlayScrollbarsComponent },
  data () {
    return {
      modal: false,
      item: null,
      tab: 0,
      attacks: {},
      height: 0,
      open: {},
      filterSeverity: [],
      filterEngine: [],
      filterClient: '',
      filterResource: '',
      filterSearch: ''
    }
  },
  computed: {
    isDark () {
      return !!this.$store.state.ui.dark
    },
    repo () {
      return repo
    },
    chevronDown () {
      return chevronDown
    },
    chevronUp () {
      return chevronUp
    },
    itemJSON () {
      if (!this.item) {
        return
      }

      return JSON.stringify(this.item, null, 4)
    },
    itemCSVURL () {
      if (!this.item) {
        return
      }

      const keys = Object.keys(this.item)
      const content = keys.join(',') + '\n' + keys.map(k => {
        const v = this.item[k]
        if (typeof v === 'object') {
          return JSON.stringify(v)
        }

        return v
      }).join(',')

      const blob = new Blob([content], { type: 'text/csv' })
      return window.URL.createObjectURL(blob)
    },
    language () {
      return this.$store.state.ui.language
    },
    attacksArr () {
      return Object.keys(this.attacks).sort((a, b) => this.attacks[a].phase - this.attacks[b].phase).map(x => this.attacks[x])
    },
    severities () {
      const colors = ['is-info', 'is-warning', 'is-danger', 'is-danger']
      return ThreatSeverities.reduce((acc, val, index) => {
        acc[val] = colors[index]
        return acc
      }, {})
    },
    engines () {
      return {
        CySafe: 'CySafe',
        CyAnalyzer: 'CyAnalyzer',
        CySandbox: 'CySandbox'
      }
    },
    filterMitre () {
      const ids = []
      Object.values(this.attacks).forEach(tactic => {
        if (tactic.checked) {
          ids.push(tactic.id)
          return
        }

        if (!tactic.children) {
          return
        }

        Object.values(tactic.children).forEach(technique => {
          if (technique.checked) {
            ids.push(technique.id)
            return
          }

          if (!technique.children) {
            return
          }

          Object.values(technique.children).forEach(subTechnique => {
            if (subTechnique.checked) {
              ids.push(subTechnique.id)
            }
          })
        })
      })

      return ids
    },
    filterParams () {
      return `&mitre=${this.filterMitre || ''}&severity=${this.filterSeverity || ''}&engine=${this.filterEngine || ''}&client=${this.filterClient || ''}&resource=${this.filterResource || ''}&query=${this.filterSearch || ''}`
    }
  },
  watch: {
    itemCSVURL (v, o) {
      if (!o) {
        return
      }

      window.URL.revokeObjectURL(o)
    },
    language (v, o) {
      if (!o) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.$nextTick(() => {
        this.$refs.table.loadSlots()
      })
    },
    filterParams () {
      this.$nextTick(() => {
        this.$refs.table.loadItems()
      })
    }
  },
  created () {
    this.loadAttacks()
  },
  methods: {
    checkAttack (e, me, parent, grand) {
      // we don't need recursion here because of the fixed structure
      if (!me) {
        return
      }

      const checked = !!e.target.checked
      this.$set(me, 'checked', checked)

      if (!checked && parent) {
        this.$set(parent, 'checked', checked)
      }

      if (!checked && grand) {
        this.$set(grand, 'checked', checked)
      }

      if (checked && parent && !parent.checked && Object.keys(parent.children).map(k => parent.children[k].checked).filter(x => !x).length === 0) {
        this.$set(parent, 'checked', checked)
      }

      if (checked && grand && !grand.checked && Object.keys(grand.children).map(k => grand.children[k].checked).filter(x => !x).length === 0) {
        this.$set(grand, 'checked', checked)
      }

      if (me.children) {
        Object.keys(me.children).forEach(xid => {
          const x = me.children[xid]
          this.$set(x, 'checked', checked)

          if (x.children) {
            Object.keys(x.children).forEach(yid => {
              const y = x.children[yid]
              this.$set(y, 'checked', checked)
            })
          }
        })
      }
    },
    onResize ({ height }) {
      this.height = height
    },
    toggleOpen (id) {
      this.$set(this.open, id, !this.open[id])
    },
    attackName (str) {
      if (!str) {
        return
      }

      return str.replaceAll('-', ' ')
    },
    attackHasChildren (item) {
      return Object.keys(item.children).length > 0
    },
    percent (v) {
      return Number(v / 1 * 100).toFixed(2) + '%'
    },
    closeModal () {
      this.modal = false
    },
    details (item) {
      this.tab = 0
      this.item = item
      this.modal = true
    },
    json (item) {
      this.tab = 1
      this.item = item
      this.modal = true
    },
    csv (item) {
      this.tab = 2
      this.item = item
      this.modal = true
    },
    itemCSVExport () {
      if (!this.item) {
        return
      }

      this.$refs.downloader.click()
    },
    itemDebounceProxy: debounce(function () {
      const args = [...arguments]
      if (args.length !== 4) {
        return
      }

      this.items.apply(this, args.slice(0, 3)).then(args[3])
    }, 1000, { maxWait: 5000 }),
    itemsDebounced () {
      return new Promise(resolve => {
        this.itemDebounceProxy(...arguments, resolve)
      })
    },
    items (filter, sort, pagination) {
      return this.$http.get(`/api/v1/threats?page=${pagination.page}&limit=${pagination.perPage}&sort=${sort.by}&order=${sort.order}${this.filterParams}`)
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return {
              total: 0,
              items: []
            }
          }

          if (data.data.total === 0) {
            return {
              total: 0,
              items: []
            }
          }

          return data.data
        })
    },
    loadAttacks () {
      return this.$http.get('/api/v1/threats/attacks')
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            this.attacks = []
          }

          this.attacks = data.data
        })
    }
  }
}
</script>
<style lang="scss">
.filters {
  .filter {
    border-bottom: 1px solid #ccc;
    .dashboard.is-dark & {
      border-color: #404553;
    }

    &:last-child {
      border: none;
    }
  }

  .checkbox {
    input[type="checkbox"] {
      margin-right: 0.5em;
    }

    & > span {
      vertical-align: middle;
      line-height: 1;
    }
  }

  & > .box__body {
    flex: 1 1 0;
    overflow: hidden;
  }
}

.tooltip {
  padding: 3px;
  background-color: gray;
  color: #fff;
  white-space: wrap;
  overflow: visible;
  max-width: 30%;
}
</style>
